import React, { useContext, useId } from 'react';
import { MQTTContext } from './MQTTConn';
import {
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  Legend
} from 'recharts';
import './Prediction.css';

/**
 * Formátuje datum do podoby "DD.MM.RRRR HH:00"
 * @param {Date} date - Datum k formátování
 * @returns {string} - Formátované datum
 */
function formatDate(date) {
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Měsíc je zero-based, takže přidáme 1
  let year = date.getFullYear();
  return `${day}.${month}.${year} ${date.getHours()}:00`;
}

/**
 * Vlastní tooltip pro graf
 * @param {object} props - Vlastnosti tooltipu
 * @returns {JSX.Element|null} - Vlastní tooltip nebo null
 */
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let selected_time = new Date(label);
    return (
      <div className="prediction-chart-tooltip">
        <p className="label">{formatDate(selected_time)}</p>
        <p className="data">Predikovaná výroba: {payload[0].value} kWh</p>
        {payload.length > 1 && (
          <p className="data-real">Reálná výroba: {payload[1].value} kWh</p>
        )}
      </div>
    );
  }

  return null;
};

/**
 * Sloučí dvě pole dat na základě klíče 'name'
 * @param {Array} data1 - První pole dat
 * @param {Array} data2 - Druhé pole dat
 * @returns {Array} - Sloučené pole dat
 */
function MergeData(data1, data2) {
  let new_data = [];
  let j = 0;

  for (let i = 0; i < data1.length; i++) {
    while (j < data2.length && data1[i].name !== data2[j].name) {
      j++;
    }

    let new_object = {};
    if (j < data2.length) {
      new_object = { ...data1[i], ...data2[j] };
    } else {
      new_object = data1[i];
    }

    new_data.push(new_object);
  }

  return new_data;
}

/**
 * Vlastní tvar pro bar s gradientem
 * @param {object} props - Vlastnosti baru
 * @returns {JSX.Element} - Vlastní bar
 */
function BarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);

  // Zjištění aktuální hodiny
  const currentHour = new Date().getHours();
  const isCurrentHour = new Date(props.payload.name).getHours() === currentHour;

  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          {/* Pokud je aktuální hodina, použij zelený gradient */}
          <stop offset={`${bar_max}%`} stopColor={isCurrentHour ? "#7DBA00" : "#e96323"} stopOpacity="1" />
          <stop offset="100%" stopColor={isCurrentHour ? "#7DBA00" : "#e96323"} stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`}
      />
    </>
  );
}

/**
 * Vlastní tvar pro aktivní bar s gradientem
 * @param {object} props - Vlastnosti aktivního baru
 * @returns {JSX.Element} - Vlastní aktivní bar
 */
function ActiveBarGradient(props) {
  const id = useId();
  const gradientId = `gradient-${id}`;
  const clipPathId = `clipPath-${id}`;

  const bar_max = 100 - Math.round((props.height / props.background.height) * 100);

  return (
    <>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset={`${bar_max}%`} stopColor="#d51317" stopOpacity="1" />
          <stop offset="100%" stopColor="#e96323" stopOpacity="0.1" />
        </linearGradient>

        <clipPath id={clipPathId}>
          <Rectangle {...props} />
        </clipPath>
      </defs>

      <rect
        x={props.x}
        width={props.width}
        height={props.background?.height}
        fill={`url(#${gradientId})`}
        y={props.background?.y}
        clipPath={`url(#${clipPathId})`}
      />
    </>
  );
}

/**
 * Komponenta pro predikci výroby FVE
 * @returns {JSX.Element} - Predikční grafy a informace
 */
const Prediction = () => {
  const mqttData = useContext(MQTTContext);

  /**
   * Formátuje tick na ose X do podoby "HH:00"
   * @param {string} tick - Hodnota ticku
   * @returns {string} - Formátovaný tick
   */
  const hourTickFormatter = (tick) => {
    const date = new Date(tick);
    return `${date.getHours()}:00`;
  };

  /**
 * Custom X-Axis Tick Component
 * @param {object} props - Properties passed from the XAxis
 * @returns {JSX.Element} - Formatted X-Axis tick
 */
const CustomXAxisTick = ({ x, y, payload }) => {
  const date = new Date(payload.value);
  const currentHour = new Date().getHours();
  const isCurrentHour = date.getHours() === currentHour;

  return (
    <text
      x={x}
      y={y + 15} // Adjust the Y position as needed
      fill={isCurrentHour ? '#fff' : '#999'} // White for current hour, grey for others
      fontWeight={isCurrentHour ? 'bold' : 'normal'} // Bold for current hour
      textAnchor="middle"
    >
      {`${date.getHours()}:00`}
    </text>
  );
};

  const CustomYAxisTick = ({ x, y, payload }) => {
    const value = payload.value;
    
    // If the value is 0, display it without line break and apply offset
    if (value === 0) {
      return (
        <text x={x - 5} y={y} fill="#999" textAnchor="end">
          {value} kWh
        </text>
      );
    }
  
    // For all other values, break the line between value and unit and apply offset
    return (
      <text x={x - 5} y={y} fill="#999" textAnchor="end">
        <tspan>{value}</tspan>
        <tspan dy="1.2em" x={x - 5}>kWh</tspan>
      </text>
    );
  };

  // Podmínky pro vykreslení prvního grafu (dnes)
  const firstGraphRender =
    mqttData.forecastData.today !== undefined &&
    mqttData.forecastData.today.date !== undefined &&
    mqttData.dateData.today !== undefined &&
    mqttData.dateData.today.date !== undefined &&
    mqttData.forecastData.today.date === mqttData.dateData.today.date;

  // Podmínky pro vykreslení druhého grafu (zítra)
  const secondGraphRender =
    mqttData.dateData.today !== undefined &&
    mqttData.dateData.today.date !== undefined &&
    mqttData.forecastData.tomorrow !== undefined &&
    mqttData.forecastData.tomorrow.date !== undefined &&
    mqttData.dateData.day_1 !== undefined &&
    mqttData.dateData.day_1.date !== undefined &&
    mqttData.forecastData.tomorrow.date === mqttData.dateData.today.date;

  return (
    <div className="prediction-container">
      {/* Grid s informacemi o FVE */}
      <div className="prediction-grid">
        <div className="prediction-grid-item">
          <h4>VYROBENO FVE - DNES</h4>
          <p>
            {mqttData.daySumData.Total_Energy_FVE_Day !== undefined
              ? `${mqttData.daySumData.Total_Energy_FVE_Day} kWh`
              : 'Načítám...'}
          </p>
        </div>
        <div className="prediction-grid-item">
          <h4>POMĚR VÝROBY/PREDIKCE - DNES</h4>
          <p>
            {mqttData.daySumData.Total_Energy_FVE_Day !== undefined &&
            mqttData.daySumData.Total_Energy_Predction !== undefined
              ? (mqttData.daySumData.Total_Energy_FVE_Day / mqttData.daySumData.Total_Energy_Predction).toFixed(2)
              : 'Načítám...'}
          </p>
        </div>
        <div className="prediction-grid-item">
          <h4>PREDIKCE FVE - DNES</h4>
          <p>
            {mqttData.daySumData.Total_Energy_Predction !== undefined
              ? `${mqttData.daySumData.Total_Energy_Predction} kWh`
              : 'Načítám...'}
          </p>
        </div>
      </div>

      {/* První graf - Predikce výroby FVE dnes */}
      {firstGraphRender && (
        <div className="prediction-wrapper">
          <div className="prediction-bar">
            <h2>
              PREDIKCE VÝROBY -{' '}
              {mqttData.forecastData.today !== undefined && mqttData.forecastData.today.date !== undefined
                ? mqttData.forecastData.today.date
                : 'Načítám...'}
            </h2>
          </div>
          <div className="prediction-bar-image">
            <img src="/images/graph.png" alt="Graph" />
          </div>
          <div className="prediction-graph">
            <div className="prediction-item">
              <ResponsiveContainer width="100%" height={600}>
                <ComposedChart
                  width={500}
                  height={300}
                  data={
                    mqttData.forecastData.today !== undefined &&
                    mqttData.forecastData.today.forecast !== undefined &&
                    mqttData.dayEnergyData.energy !== undefined
                      ? MergeData(mqttData.forecastData.today.forecast, mqttData.dayEnergyData.energy)
                      : []
                  }
                  margin={{
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5} />
                  <XAxis 
                    dataKey="name" 
                    tick={<CustomXAxisTick />} 
                    tickCount={24} // Nastaví počet ticků na 24 (pro každou hodinu v denním intervalu)
                    interval={0} // Zajistí, že se zobrazí tick pro každou hodinu
                  />
                  <YAxis tick={<CustomYAxisTick />} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    name="Předpokládaná výroba FVE"
                    dataKey="watt_hours"
                    fill="#e96323"
                    shape={<BarGradient />}
                    activeBar={<ActiveBarGradient />}
                    animationDuration={4000}
                  />
                  <Line
                    name="Reálná výroba FVE"
                    type="monotone"
                    dataKey="Energy_FVE"
                    stroke="#7dba00"
                    strokeWidth={3}
                    animationDuration={2000}
                    animationEasing="linear"
                  />
                  <Legend
                    layout="horizontal"
                    verticalAlign="bottom"
                    align="center"
                    wrapperStyle={{
                    paddingTop: "10px", 
                    }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
              <p>* predikce výroby FVE je pouze orientačního charakteru na základě předpovědi počasí</p>
            </div>
          </div>
        </div>
      )}

      {/* Druhý graf - Predikce výroby FVE zítra */}
      {secondGraphRender && (
        <div className="prediction-wrapper">
          <div className="prediction-bar">
            <h2>
              PREDIKCE VÝROBY -{' '}
              {mqttData.dateData.day_1 !== undefined && mqttData.dateData.day_1.date !== undefined
                ? mqttData.dateData.day_1.date
                : 'Načítám...'}
            </h2>
          </div>
          <div className="prediction-bar-image">
            <img src="/images/Graph.png" alt="Graph" />
          </div>
          <div className="prediction-graph">
            <div className="prediction-item">
              <ResponsiveContainer width="100%" height={600}>
                <ComposedChart
                  width={500}
                  height={300}
                  data={
                    mqttData.forecastData.tomorrow !== undefined &&
                    mqttData.forecastData.tomorrow.forecast !== undefined
                      ? mqttData.forecastData.tomorrow.forecast
                      : []
                  }
                  margin={{
                    top: 5,
                    right: 20,
                    left: 30,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray="1 0" vertical={false} opacity={0.5} />
                  <XAxis dataKey="name" tickFormatter={hourTickFormatter} />
                  <YAxis tick={<CustomYAxisTick />} />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    name="Předpokládaná výroba FVE"
                    dataKey="watt_hours"
                    fill="#e96323"
                    shape={<BarGradient />}
                    activeBar={<ActiveBarGradient />}
                  />
                  <Legend />
                </ComposedChart>
              </ResponsiveContainer>
              <p>* predikce výroby FVE je pouze orientačního charakteru na základě předpovědi počasí</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Prediction;
