import React, { useState } from 'react';
import patchNotes from './patchNotes.json';
import './footer.css';

function Footer() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = (e) => {
    if (e.target.className === 'modal') {
      setShowModal(false);
    }
  };

  return (
    <footer className="footer">
      © 2024 METRICA&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;YOUNG4SERVICES s.r.o.&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Powered by YOUNG4ENERGY s.r.o.
      <div className="version" onClick={toggleModal}>
        Verze aplikace: {patchNotes.version}
      </div>

      {/* Modal pro Patch Notes */}
      {showModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content animate-modal">
            <h2>Patch Notes</h2>
            <div className="modal-body">
              {patchNotes.patchNotes.map((note) => (
                <div key={note.version}>
                  <h3>Verze {note.version} - {note.date}</h3>
                  <ul>
                    {note.changes.map((change, index) => {
                      const [boldText, normalText] = change.split(':');
                      return (
                        <li key={index}>
                          <strong>{boldText}:</strong>
                          <span className="normal-text">{normalText}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
            <button onClick={toggleModal}>Zavřít</button>
          </div>
        </div>
      )}
    </footer>
  );
}

export default Footer;
