import React from 'react';

const Dashboards = () => {
  const grafanaDashboardUrl = 'https://pav.sapeli.metrica.cz/grafana/public-dashboards/7b5a9fab6e5148d494667dc276397ba2';

  const handleClick = () => {
    window.open(grafanaDashboardUrl, '_blank');
  };

  return (
    <div
      style={{ height: '100vh', width: '100%', cursor: 'pointer' }}
      onClick={handleClick}
    >
      <div style={{ textAlign: 'center', marginTop: '20%' }}>
        <h1>Vítejte na stránce Dashboardů</h1>
        <p>Klikněte kdekoliv na stránku pro otevření dashboardu v nové záložce.</p>
      </div>
    </div>
  );
};

export default Dashboards;
