// src/components/Reports.js

import React, { useState, useEffect, useRef } from 'react';
import publishToMQTT from './MQTTPublish';
import mqtt from 'mqtt';
import {
  Container,
  Typography,
  Box,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
  FormHelperText,
  Tooltip,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { LoadingButton } from '@mui/lab';
import MuiAlert from '@mui/material/Alert';
import './Reports.css';
import Slide from '@mui/material/Slide'; 

// Alert component for Snackbar
const AlertToast = React.forwardRef(function AlertToast(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Reports = () => {
  // State variables
  const [selectedReportType, setSelectedReportType] = useState('basic'); // 'basic' or 'advanced'
  const [selectedType, setSelectedType] = useState('monthly'); // 'monthly' or 'yearly' for advanced
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportFormat, setReportFormat] = useState('csv'); // Default 'csv' for consistency with resetForm
  const [loading, setLoading] = useState(false);
  
  // Snackbar state
  const [toast, setToast] = useState({
    open: false,
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
    message: '',
  });
  
  const [downloadLink, setDownloadLink] = useState(''); // Download link for the report

  // Ref to store timeout ID
  const timeoutRef = useRef(null);

  // Get today's date in YYYY-MM-DD format
  const today = new Date();
  const todayDate = today.toISOString().split('T')[0];
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // getMonth() returns 0-11

  // Function to close Snackbar
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prev) => ({ ...prev, open: false }));
  };

  // Slide transition for Snackbar
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleGenerateReport = () => {
    // Input validation
    let valid = true;
    let errorMsg = '';
  
    if (selectedReportType === 'basic') {
      if (!startDate || !endDate) {
        errorMsg = 'Prosím vyberte počáteční a konečné datum.';
        valid = false;
      }
    } else if (selectedReportType === 'advanced') {
      if (selectedType === 'monthly' && !selectedMonth) {
        errorMsg = 'Prosím vyberte měsíc.';
        valid = false;
      }
      if (!selectedYear) {
        errorMsg = 'Prosím vyberte rok.';
        valid = false;
      }
    }
  
    if (!valid) {
      setToast({
        open: true,
        severity: 'error',
        message: errorMsg,
      });
      return;
    }
  
    setLoading(true);
    setDownloadLink('');
    
    // Start timeout (e.g., 30 seconds)
    timeoutRef.current = setTimeout(() => {
      setToast({
        open: true,
        severity: 'error',
        message: 'Požadovaný report nebylo možné vytvořit. Zkuste to prosím znovu.',
      });
      setLoading(false);
    }, 30000); // 30,000 ms = 30 seconds
    
    // Prepare payload for MQTT publishing
    let payload = {
      reportType: selectedReportType,
      format: reportFormat,
    };
  
    if (selectedReportType === 'basic') {
      payload.startDate = startDate;
      payload.endDate = endDate;
    } else if (selectedReportType === 'advanced') {
      payload.type = selectedType; // Add report type
      payload.year = selectedYear.toString();
      if (selectedType === 'monthly') {
        payload.month = selectedMonth;
      }
      // No need to add 'month' for yearly reports
    }
  
    const payloadString = JSON.stringify(payload);
    console.log("Publishing payload:", payloadString); // For debugging
  
    // Publish to MQTT with callback
    publishToMQTT('reports/request', payloadString, (err) => {
      if (err) {
        setToast({
          open: true,
          severity: 'error',
          message: 'Chyba při odesílání reportu.',
        });
        setLoading(false);
        console.error('Publish error:', err);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current); // Clear timeout on error
        }
      } else {
        setToast({
          open: true,
          severity: 'success',
          message: 'Požadavek na generování reportu byl odeslán.',
        });
        // Do not setLoading(false) here; wait for the response or timeout
      }
    });
  };  

  // MQTT subscription for responses
  useEffect(() => {
    const client = mqtt.connect('wss://pav.sapeli.metrica.cz:9001', {
      clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
      username: 'mendry',
      password: 'Kraken14',
      clean: true,
      rejectUnauthorized: false,
    });

    client.on('connect', () => {
      console.log('Connected to MQTT server, subscribing to topic: reports/response');
      client.subscribe('reports/response', (err) => {
        if (err) {
          console.error('Subscription error:', err);
        }
      });
    });

    client.on('message', (topic, message) => {
      const parsedMessage = JSON.parse(message.toString());
      if (parsedMessage.downloadLink) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current); // Clear the timeout if response is received
          timeoutRef.current = null;
        }
        setDownloadLink(parsedMessage.downloadLink);
        setToast({
          open: true,
          severity: 'info',
          message: 'Report je připraven ke stažení.',
        });
        setLoading(false);
      }
    });

    client.on('error', (err) => {
      console.error('MQTT Client Error:', err);
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear timeout on component unmount
      }
      client.end(); // Cleanup on component unmount
    };
  }, []);

  // Generate list of months for dropdown
  const allMonths = [
    { value: '01', label: 'Leden' },
    { value: '02', label: 'Únor' },
    { value: '03', label: 'Březen' },
    { value: '04', label: 'Duben' },
    { value: '05', label: 'Květen' },
    { value: '06', label: 'Červen' },
    { value: '07', label: 'Červenec' },
    { value: '08', label: 'Srpen' },
    { value: '09', label: 'Září' },
    { value: '10', label: 'Říjen' },
    { value: '11', label: 'Listopad' },
    { value: '12', label: 'Prosinec' },
  ];

  // Generate list of years from 2020 to current year
  const years = Array.from({ length: currentYear - 2023 }, (_, i) => 2024 + i);

 // Funkce pro filtrování měsíců na základě vybraného roku
 const getFilteredMonths = () => {
  if (selectedYear === currentYear) {
    // Pokud je vybraný aktuální rok, zobrazí se měsíce pouze do aktuálního měsíce
    return allMonths.filter(month => parseInt(month.value) <= currentMonth);
  }
  // Pokud je vybraný jiný rok než aktuální, zobrazí všechny měsíce
  return allMonths;
};

// useEffect pro resetování vybraného měsíce při změně roku
useEffect(() => {
  setSelectedMonth(''); // Resetuje vybraný měsíc při změně roku
}, [selectedYear]);

  // Generování seznamu měsíců na základě vybraného roku
  const months = getFilteredMonths();

  const resetForm = () => {
    setSelectedReportType('basic');
    setSelectedType('monthly');
    setSelectedMonth('');
    setSelectedYear('');
    setStartDate('');
    setEndDate('');
    setReportFormat('csv');
    setDownloadLink('');
    setLoading(false);
  };

  const handleDownloadClick = () => {
    setToast({
      open: true,
      severity: 'success',
      message: 'Report byl úspěšně stažen!',
    });
    setTimeout(() => {
      resetForm();
      setLoading(false);
    }, 2000);
  };

  // useEffect to automatically reset dependent fields when report type changes
  useEffect(() => {
    if (selectedReportType === 'basic') {
      // Reset only relevant states for 'basic'
      setSelectedType('monthly');
      setSelectedMonth('');
      setSelectedYear('');
      // Reset startDate and endDate
      setStartDate('');
      setEndDate('');
    } else if (selectedReportType === 'advanced') {
      // Reset only relevant states for 'advanced'
      setSelectedMonth('');
      setSelectedYear('');
      setStartDate('');
      setEndDate('');
      setSelectedType('monthly'); // Default value
    }
  }, [selectedReportType]);

  const minDate = '2024-01-01';
  const maxDate = todayDate;

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;
    if (selectedDate > maxDate) {
      setStartDate(maxDate);
    } else {
      setStartDate(selectedDate);
    }
  };
  
  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;
    if (selectedDate > maxDate) {
      setEndDate(maxDate);
    } else {
      setEndDate(selectedDate);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Generování reportů
        </Typography>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={4}>
            {/* Section: Report Type and Advanced Report Type */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Report Type */}
                <Grid item xs={12} sm={selectedReportType === 'advanced' ? 6 : 12}>
                  <Typography variant="h6" gutterBottom className="report-section-title">
                    Typ reportu
                  </Typography>
                  <FormControl component="fieldset" disabled={loading}>
                    <RadioGroup
                      row
                      aria-label="report-type"
                      name="report-type"
                      value={selectedReportType}
                      onChange={(e) => setSelectedReportType(e.target.value)}
                    >
                      <FormControlLabel value="basic" control={<Radio />} label="Základní" />
                      <FormControlLabel value="advanced" control={<Radio />} label="Pokročilý" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {/* Advanced Report Type */}
                {selectedReportType === 'advanced' && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className="report-section-title">
                      Typ pokročilého reportu
                    </Typography>
                    <FormControl component="fieldset" disabled={loading}>
                      <RadioGroup
                        row
                        aria-label="advanced-report-type"
                        name="advanced-report-type"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                      >
                        <FormControlLabel value="monthly" control={<Radio />} label="Měsíční" />
                        <FormControlLabel value="yearly" control={<Radio />} label="Roční" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Conditional Rendering Based on Report Type */}
            {selectedReportType === 'basic' ? (
              <>
                {/* Section: Date Range */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom className="report-section-title">
                    Časový interval
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Start Date */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Tooltip title="Vyberte počáteční datum pro generování reportu." arrow>
                          <TextField
                            label="Počátek výběru"
                            type="date"
                            disabled={loading}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={startDate}
                            onChange={handleStartDateChange}
                            onInput={(e) => {
                              if (e.target.value > maxDate) {
                                e.target.value = maxDate;
                              }
                            }}
                            inputProps={{
                              min: minDate,
                              max: maxDate,
                            }}
                            error={
                              toast.open &&
                              toast.severity === 'error' &&
                              toast.message.includes('počáteční a konečné datum')
                            }
                            helperText={
                              toast.open &&
                              toast.severity === 'error' &&
                              toast.message.includes('počáteční a konečné datum') &&
                              'Prosím vyberte počáteční a konečné datum.'
                            }
                          />
                        </Tooltip>
                      </FormControl>
                    </Grid>

                    {/* End Date */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Tooltip title="Vyberte konečné datum pro generování reportu." arrow>
                          <TextField
                            label="Konec výběru"
                            type="date"
                            disabled={loading}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={endDate}
                            onChange={handleEndDateChange}
                            inputProps={{
                              min: startDate || minDate, // Minimální datum je startDate nebo 1. leden 2024
                              max: maxDate,
                            }}
                            error={
                              toast.open &&
                              toast.severity === 'error' &&
                              toast.message.includes('počáteční a konečné datum')
                            }
                            helperText={
                              toast.open &&
                              toast.severity === 'error' &&
                              toast.message.includes('počáteční a konečné datum') &&
                              'Prosím vyberte počáteční a konečné datum.'
                            }
                          />
                        </Tooltip>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {/* Section: Advanced Options */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom className="report-section-title">
                    Pokročilé Možnosti
                  </Typography>
                  <Grid container spacing={2}>
                    {/* Select Year */}
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={
                          toast.open &&
                          toast.severity === 'error' &&
                          toast.message.includes('rok')
                        }
                      >
                        <InputLabel id="year-label">Vyberte rok</InputLabel>
                        <Select
                          labelId="year-label"
                          id="year-select"
                          value={selectedYear}
                          label="Vyberte rok"
                          disabled={loading}
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          <MenuItem value="">
                            <em>Vyberte rok</em>
                          </MenuItem>
                          {years.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                        {toast.open &&
                          toast.severity === 'error' &&
                          toast.message.includes('rok') && (
                            <FormHelperText>{toast.message}</FormHelperText>
                          )}
                      </FormControl>
                    </Grid>

                    {/* Select Month */}
                    {selectedType === 'monthly' && (
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          error={
                            toast.open &&
                            toast.severity === 'error' &&
                            toast.message.includes('měsíc')
                          }
                        >
                          <InputLabel id="month-label">Vyberte měsíc</InputLabel>
                          <Select
                            labelId="month-label"
                            id="month-select"
                            value={selectedMonth}
                            label="Vyberte měsíc"
                            disabled={loading}
                            onChange={(e) => setSelectedMonth(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Vyberte měsíc</em>
                            </MenuItem>
                            {months.map((month) => (
                              <MenuItem key={month.value} value={month.value}>
                                {month.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {toast.open &&
                            toast.severity === 'error' &&
                            toast.message.includes('měsíc') && (
                              <FormHelperText>{toast.message}</FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Section: Report Format */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom className="report-section-title">
                    Formát reportu
                  </Typography>
                  <FormControl component="fieldset" disabled={loading}>
                    <RadioGroup
                      row
                      aria-label="report-format"
                      name="report-format"
                      value={reportFormat}
                      onChange={(e) => setReportFormat(e.target.value)}
                    >
                      <FormControlLabel
                        value="csv"
                        control={<Radio />}
                        label="CSV"
                      />
                      <FormControlLabel
                        value="XLSX"
                        control={<Radio />}
                        label="EXCEL"
                      />
                      {/* Zakomentujeme možnost exportu do PDF */}
                      {/* <FormControlLabel
                        value="pdf"
                        control={<Radio />}
                        label="PDF"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            )}

            {/* Report Format for Basic Reports */}
            {selectedReportType === 'basic' && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className="report-section-title">
                  Formát reportu
                </Typography>
                <FormControl component="fieldset" disabled={loading}>
                  <RadioGroup
                    row
                    aria-label="report-format"
                    name="report-format"
                    value={reportFormat}
                    onChange={(e) => setReportFormat(e.target.value)}
                  >
                    <FormControlLabel
                      value="csv"
                      control={<Radio />}
                      label="CSV"
                    />
                    <FormControlLabel
                      value="XLSX"
                      control={<Radio />}
                      label="EXCEL"
                    />
                    {/* Zakomentujeme možnost exportu do PDF */}
                    {/* <FormControlLabel
                      value="pdf"
                      control={<Radio />}
                      label="PDF"
                    /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            {/* Button to Generate Report */}
            <Grid item xs={12} align="center">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleGenerateReport}
                loading={loading}
                loadingIndicator="Generuji..."
                startIcon={!loading && <AnalyticsIcon />}
                disabled={loading}
                sx={{ width: '100%', padding: '12px', fontSize: '16px' }}
              >
                Vygenerovat Report
              </LoadingButton>
            </Grid>

            {/* Display Download Link */}
            {downloadLink && (
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  href={downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadClick}
                  sx={{ padding: '10px 20px', fontSize: '16px' }}
                >
                  Stáhnout Report
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>

      {/* Backdrop with CircularProgress for Loading Animation */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Snackbar for toast messages */}
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position at bottom center
        TransitionComponent={SlideTransition} // Use Slide transition
        sx={{ bottom: { xs: 100, sm: 140, md: 40 } }} // Add spacing from bottom
      >
        <AlertToast
          onClose={handleCloseToast}
          severity={toast.severity}
          sx={{ width: '100%' }}
        >
          {toast.message}
        </AlertToast>
      </Snackbar>
    </Container>
  );
};

export default Reports;
