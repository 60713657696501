// src/components/MQTTPublish.js
import mqtt from 'mqtt';

/**
 * Publishes a message to a specified MQTT topic.
 *
 * @param {string} topic - The MQTT topic to publish to.
 * @param {string} message - The message to publish.
 * @param {function} callback - A callback function to handle success or error.
 */
const publishToMQTT = (topic, message, callback) => {
  const clientId = `mqttjs_${Math.random().toString(16).substr(2, 8)}`;
  const client = mqtt.connect(process.env.REACT_APP_MQTT_BROKER, {
    clientId: clientId,
    username: process.env.REACT_APP_MQTT_USERNAME,
    password: process.env.REACT_APP_MQTT_PASSWORD,
    clean: true,
    rejectUnauthorized: false
  });

  client.on('connect', () => {
    console.log('Connected to MQTT server, publishing to topic:', topic);
    client.publish(topic, message, (err) => {
      if (callback) callback(err); // Invoke callback with error if any
      if (err) {
        console.error('Error while publishing:', err);
      } else {
        console.log(`Message "${message}" published to topic "${topic}"`);
      }
      client.end(); // Close connection after publishing
    });
  });

  client.on('error', (err) => {
    console.error('MQTT Connection error:', err);
    if (callback) callback(err); // Invoke callback on connection error
  });
};

export default publishToMQTT;
