import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Menu from './components/Menu';
import LoginPage from './components/LoginPage'; 
import EnergyFlowOverview from './components/EnergyFlowOverview';
import FVEOverview from './components/FVEOverview';
import BESSOverview from './components/BESSOverview';
import Dashboard from './components/Dashboards';
import SpotMarket from './components/SpotMarket';
import Home from './components/Home';
import MQTTDataDisplay from './components/MQTTDataDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Diagram from './components/Diagram';
import Electricity from './components/Electricity';
import Prediction from './components/Prediction';
import Economics from './components/Economics';
import Alerts from './components/Alerts';
import Reports from './components/Reports';
import Test from './components/test';
import { MQTTContextProvider } from './components/MQTTConn';
import Footer from './components/footer';  // Importujeme komponentu Footer

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem('isAuthenticated');
    if (loggedIn) {
      setIsAuthenticated(true);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    sessionStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <Router>
      <div className="main-container">
        {!isAuthenticated ? (
          <LoginPage onLogin={handleLogin} />
        ) : (
          <>
            <Menu isOpen={menuOpen} toggleMenu={toggleMenu} />
            <MQTTContextProvider>
              <div className="content-area">
                <header className="app-header">
                  <div className="logo-container">
                    <Link to="/">
                      <img src="/images/LOGO-METRICA_white.png" alt="Metrica Logo" className="metrica-logo" />
                    </Link>
                  </div>
                  <div className="site-title-container">
                    <div className="site-title">
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="location-icon" />
                      SAPELI Group, a.s. - areál PÁVOVSKÁ
                    </div>
                  </div>
                </header>

                <MQTTDataDisplay />

                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/energy-flow" element={<Diagram />} /> {/* Grafické rozvržení */}
                  <Route path="/overview" element={<EnergyFlowOverview />} /> {/* Základní přehled */}
                  <Route path="/fve" element={<FVEOverview />} /> {/* Přehled FVE */}
                  <Route path="/bess" element={<BESSOverview />} /> {/* Přehled BESS */}
                  <Route path="/electricity" element={<Electricity />} /> {/* Měřící body instalace */}
                  <Route path="/spot-market" element={<SpotMarket />} /> {/* Spotový trh */}
                  <Route path="/prediction" element={<Prediction />} /> {/* Predikce výroby */}
                  <Route path="/dashboards" element={<Dashboard />} /> {/* Dashboardy */}
                  <Route path="/economics" element={<Economics />} /> {/* Ekonomika */}
                  <Route path="/alerts" element={<Alerts />} /> {/* Alerty */}
                  <Route path="/reports" element={<Reports />} /> {/* Reporty */}
                  <Route path="/test" element={<Test />} /> {/* Testovací stránka */}
                </Routes>

                <Footer />  {/* Zde přidáš komponentu Footer */}
              </div>
            </MQTTContextProvider>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
