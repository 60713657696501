import React from 'react';

const Economics = () => {
  return (
    <div>
      <h1>Ekonomika</h1>
      <p>Zde bude stránka s přehledem ekonomických údajů.</p>
    </div>
  );
};

export default Economics;
